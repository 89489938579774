import { FormValidator } from "@syncfusion/ej2-react-inputs";
import IconComponent from "../../cubeComponent/icons";
import { presetSectionsCodeObject } from "../PortalSetings/Configuration/FormsAndFields/constants";
import { optionsViewType } from "../PortalSetings/Configuration/FormsAndFields/FormTemplate/constant.helper";
import { formStatuses } from "./constants";

export const getFileIcon = (fileName) => {
  const fileExtension = fileName.split(".").pop().toLowerCase();
  if (fileName && fileExtension) {
    return <IconComponent name={`File${fileExtension.toUpperCase()}`} />;
  }
  return <IconComponent name="FileNONE" />;
};
export const statusTemplate = (stage) => {
  let status = "";
  let color;
  if (stage == formStatuses.inProgress) {
    status = "In progress";
    color = "bg-system_colour_12";
  }
  if (stage == formStatuses.submitted) {
    status = "Submitted";
    color = "bg-system_colour_45";
  }
  if (stage == formStatuses.approved) {
    status = "Approved";
    color = "bg-positive_communication";
  }
  if (stage == formStatuses.rejected) {
    status = "Rejected";
    color = "bg-system_colour_8";
  }
  return (
    <div
      className={`h-8 ${color} w-fit pl-3 gap-2 bg-opacity-30 rounded-2xl pr-3 flex items-center`}
    >
      <span className={`${color} rounded-full h-3 w-3`} />
      <span className="font-semibold">{status}</span>
    </div>
  );
};
export const addValidationToInputFields = ({
  formData,
  template = [],
  formObject,
  rows = [],
  modifySection = [],
}) => {
  const allSectionsInCurrentLevel = [];
  const excludedTypes = ["users", "fileUpload", "image", "url"];
  if (formData && formData._id) {
    formData.formFlow[formData.currentLevelOfForm].dataSource.map((data) => {
      if (data.type === "Fill") {
        data.sections.map((section) =>
          allSectionsInCurrentLevel.push(section._id),
        );
      } else if (data.type === "Modify") {
        data.sections.map((section) => {
          if (modifySection.includes(section._id)) {
            allSectionsInCurrentLevel.push(section._id);
          }
        });
      }
    });
    template = formData.selectedForm.template;
  } else if (template && template.length) {
    template.forEach((section) => allSectionsInCurrentLevel.push(section._id));
  } else if (formData && formData.selectedForm) {
    template = formData.selectedForm.template || [];
    template.forEach((section) => allSectionsInCurrentLevel.push(section._id));
  }

  const options = { rules: {} };
  template.some((section) => {
    if (
      section.sectionCode == presetSectionsCodeObject.tableSection &&
      allSectionsInCurrentLevel.includes(section._id)
    ) {
      section.questionFields.some((eachField) => {
        section.tableDataSource.some((row) => {
          const isSelectWithListView =
            (eachField.fieldType === "multi-select" ||
              eachField.fieldType === "single-select") &&
            eachField.viewType === optionsViewType.LIST;
          if (
            !excludedTypes.includes(eachField.fieldType) &&
            !isSelectWithListView
          ) {
            const ruleObj = {};
            const ruleName = `${row.rowId}${eachField._id}`;
            if (eachField.isMandatory === true) {
              ruleObj.required = [true, "* Required"];
            }
            if (["single-line", "multi-line"].includes(eachField.fieldType)) {
              ruleObj.minLength = eachField.minFieldLimit ?? 0;
              if (eachField.maxFieldLimit) {
                ruleObj.maxLength = eachField.maxFieldLimit;
              }
            } else if (["date-time"].includes(eachField.fieldType)) {
              ruleObj.regex = [
                "^([0-2][0-9]|3[0-1])/((0[1-9])|(1[0-2]))/([1-9][0-9]{3}) ((0[1-9])|(1[0-2])):([0-5][0-9]):(AM|PM)$",
                "Please enter a valid date",
              ];
            }
            options.rules[ruleName] = {
              ...ruleObj,
            };
            if (rows && Array.isArray(rows)) {
              rows.forEach((row) => {
                if (!row.isFolder) {
                  options.rules[`${row.id}_${ruleName}`] = {
                    ...ruleObj,
                  };
                }
              });
            } else {
              options.rules[ruleName] = {
                ...ruleObj,
              };
            }
          }
        });
      });
    } else if (section.questionFields) {
      if (allSectionsInCurrentLevel.includes(section._id)) {
        section.questionFields.some((eachField) => {
          const isSelectWithListView =
            (eachField.fieldType === "multi-select" ||
              eachField.fieldType === "single-select") &&
            eachField.viewType === optionsViewType.LIST;
          if (
            !excludedTypes.includes(eachField.fieldType) &&
            !isSelectWithListView
          ) {
            const ruleName = `${eachField._id}`;
            const ruleObj = {};
            if (eachField.isMandatory === true) {
              ruleObj.required = [true, "* Required"];
            }
            if (["single-line", "multi-line"].includes(eachField.fieldType)) {
              ruleObj.minLength = eachField.minFieldLimit ?? 0;
              if (eachField.maxFieldLimit) {
                ruleObj.maxLength = eachField.maxFieldLimit;
              }
            } else if (["date-time"].includes(eachField.fieldType)) {
              ruleObj.regex = [
                "^([0-2][0-9]|3[0-1])/((0[1-9])|(1[0-2]))/([1-9][0-9]{3}) ((0[1-9])|(1[0-2])):([0-5][0-9]):(AM|PM)$",
                "Please enter a valid date",
              ];
            }
            if (rows && Array.isArray(rows)) {
              rows.forEach((row) => {
                if (!row.isFolder) {
                  options.rules[`${row.id}_${ruleName}`] = {
                    ...ruleObj,
                  };
                }
              });
            } else {
              options.rules[ruleName] = {
                ...ruleObj,
              };
            }
          }
        });
      }
    }
    formObject.current = new FormValidator("#formData", options);
  });
};
