import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { forwardRef, useRef } from "react";

const AutoComplete = forwardRef((props: any, ref: any) => {

    const btnRef = ref || useRef();

    return (
        <Autocomplete
            {...props}
            sx={{
                ...(props.sx) ? props.sx : {},
                '& .MuiInputBase-root': {
                    padding: '2px 10px'
                }
            }}
            err
            ref={btnRef}
        >
            {props.children}
        </Autocomplete>
    )
})
export default AutoComplete