import { useRef } from "react";
import { useParams } from "react-router";
import { Query } from "@syncfusion/ej2-data";
import { formSectionType } from "../../components/CreateInspectionAndProgress/constants";
import useForms from "../../components/CreateInspectionAndProgress/useForms";
import { getIconFromFieldType } from "../../components/PortalSetings/Configuration/FormsAndFields/FormTemplate/constants";
import { optionsViewType } from "../../components/PortalSetings/Configuration/FormsAndFields/FormTemplate/constant.helper";
import { BiLinkExternal } from "react-icons/bi";
import { PlusIcon } from "../../components/StickySidebarViewer/assets/selectionIcons";
// import { getFileIcon } from "../../../../mobile/src/Components/Services/globalFunction";
import { teamsService, locationService } from "../dataManager";
import TextBoxComponent from "@CubeComponents/form/TextBoxComponent";
import RadioButtonComponent from "@CubeComponents/form/RadioButtonComponent";
import CheckboxComponent from "@CubeComponents/form/CheckboxComponent";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import {
  NumericTextBoxComponent,
  TextAreaComponent,
} from "@syncfusion/ej2-react-inputs";
import {
  DropDownListComponent,
  DropDownTreeComponent,
} from "@syncfusion/ej2-react-dropdowns";
import MultiSelectComponent from "../../cubeComponent/form/MultiselectComponent";
import IconComponent from "@CubeComponents/icons";
import ButtonComponent from "@CubeComponents/form/ButtonComponent";
import { BUTTON_TYPES } from "@CubeComponents/constants/globalVariable";
import { FormValidator } from "@syncfusion/ej2-inputs";
import {
  formCategoryObject,
  presetSectionsCodeObject,
} from "../../components/PortalSetings/Configuration/FormsAndFields/constants";
import { convertDate } from "../luxonHelper";

const useCustomFields = () => {
  const params = useParams();
  const locationQuery = new Query().addParams("projectId", params.projectId);

  const validateFormWithId = (id, template, formValidator) => {
    const rules = [];
    template?.forEach((section) => {
      section.questionFields.forEach((item) => {
        rules.push({ name: `${id}_${item._id}`, value: item.answer?.value });
      });
    });
    let valid = true;
    rules.forEach((rule) => {
      const ruleValid = formValidator?.validate({
        name: rule.name,
        value: rule.value,
      });
      valid = valid && ruleValid;
    });
    return valid;
  };

  return {
    validateFormWithId,
  };
};

export const getNomenclatureField = (
  args,
  field,
  index,
  value,
  handleNomenclatureChange,
) => {
  if (field.type == "text" || field.type == "number") {
    const char = field.type == "text" ? "A" : "1";
    const placeholderText = field.allowedCharacters
      ? char.repeat(field.allowedCharacters)
      : `${char.repeat(3)}...`;
    const htmlAttributes = {};
    if (field.allowedCharacters) {
      htmlAttributes.maxlength = `${field.allowedCharacters}`;
    }
    const handleInput = (e) => {
      const maxLength = field.allowedCharacters;
      if (maxLength && e.value.length > maxLength) {
        e.event.target.value = e.value.slice(0, maxLength);
      }
    };

    return (
      <TextBoxComponent
        id={`${args.id}-${field.type}-${index}`}
        key={`${args.id}-${field.type}-${index}`}
        type={field.type}
        onClick={(e) => e.stopPropagation()}
        value={value}
        placeholder={placeholderText}
        className="flex-1"
        htmlAttributes={htmlAttributes}
        input={handleInput}
        onBlur={(e) => handleNomenclatureChange(e, args, field, index)}
      />
    );
  }
  if (field.type == "single select") {
    return (
      <DropDownListComponent
        id={`${args.id}-${field.type}-${index}`}
        key={`${args.id}-${field.type}-${index}`}
        className="flex-1"
        placeholder="Select an option"
        dataSource={field.options}
        value={value}
        onChange={(e) => handleNomenclatureChange(e, args, field, index)}
      />
    );
  }
};

export default useCustomFields;
