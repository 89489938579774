import React, { useCallback, useEffect, useState } from "react";
import { optionsViewType } from "../PortalSetings/Configuration/FormsAndFields/FormTemplate/constant.helper";
import { formSectionType, getPermissions,onURLClick } from "./constants";
import { convertDate } from "../../services/luxonHelper";
import IconComponent from "../../cubeComponent/icons";
import { BUTTON_TYPES } from "../../cubeComponent/constants/globalVariable";
import ButtonComponent from "../../cubeComponent/form/ButtonComponent";
import { PlusIcon } from "../StickySidebarViewer/assets/selectionIcons";
import ImageEditor from "../ImageEditor";
import { Box, Chip, CircularProgress, FormControl, FormControlLabel, FormGroup, FormHelperText, ListItemText, MenuItem, RadioGroup } from "@mui/material";
import _ from "lodash";
import { teamsService, locationService } from "../../services/dataManager";
import { Query } from "@syncfusion/ej2-data";
import { useParams } from "react-router-dom";
import UserNameTagComponent from "../../cubeComponent/html/UserNameTagComponent";
import UserCard from "./userCard";
import { getFileIcon } from "./formsHelpers";
import { BiLinkExternal } from "react-icons/bi";
import { DropDownTreeComponent } from "@syncfusion/ej2-react-dropdowns";
import MultiSelectComponent from "../../cubeComponent/form/MultiselectComponent";
import TextBoxComponent from "../../cubeComponent/form/TextBoxComponent";
import RadioButtonComponent from "../../cubeComponent/form/RadioButtonComponent";
import DropDownListComponent from "../../cubeComponent/form/DropdownListComponent";
import CheckboxComponent from "../../cubeComponent/form/CheckboxComponent";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import TextFieldComponent from "../../cubeComponent/Mui_Form/TextBoxComponent";
import RadioButton from "../../cubeComponent/Mui_Form/RadioButton";
import CheckBoxComponent from "../../cubeComponent/Mui_Form/CheckBox";
import { useDispatch } from "react-redux";
import { showToastNotification } from "../../actions/toast";
import { TOAST_TYPE_ERROR, TOAST_TYPE_WARNING } from "../../services/constants";
import { v4 as uuid } from "uuid";
import DateTimeComponent from "../../cubeComponent/Mui_Form/DateTimeComponent";
import SelectDropDown from "../../cubeComponent/Mui_Form/SelectDropDown";
import AutoComplete from "../../cubeComponent/Mui_Form/AutoComplete";
import ValidationWrapperWeb from "../FormValidation/ValidationWrapperWeb";

const FormQuestionFields = ({
  eachField,
  fieldIndex=null,
  sectionIndex=null,
  section={},
  source=formSectionType.FORM,
  tableRowIndex = 0,
  tableRowId = null,
  formValidator={},
  disabled=false,
  handleCustomFieldData = null,
  showAnswersOnly = false,
  formData={},
  width = "100%",
  userDetails={},
  multiTextRows=5,
  handleInputChange,
  handleChangeAnswer,
  handleURLModalState,
  isFileUploading,
  handleFileUploadChange,
  removeUserFromList,
  saveEditedImage,
  handleRemoveUploadedFile,
  openAddUserModal,
  extraParams={}
}) => {

  const params = useParams();
  // const dispatch = useDispatch()
  const lookUpQuery = new Query().addParams("projectId", params.projectId);
  const userId = userDetails.id
  const [teamsData,setTeamsData] = useState([])
  const [locationData,setLocationData] = useState([])

  const {
    _id,
    fieldType,
    answerChoices,
    placeholderText,
    lookUpAnsFieldType,
    minFieldRange,
    maxFieldRange,
    viewType = optionsViewType.LIST,
    selectionScope,
    maxNoOfFiles,
    allowedFileTypes=[]
  } = eachField;

  const questionId = _id;
  const name = tableRowId ? `${tableRowId}${questionId}` : questionId;
  const { permissions,tableDataSource } = section;
  const sectionId = section._id;

  let currentAnswer = eachField.answer ?? {};
  if (source == formSectionType.TABLE_SECTION) {
    currentAnswer = tableDataSource[tableRowIndex]?.[_id] ?? {};
  }
  const isCurrentUserAllowedToFill = permissions ? getPermissions({ inspectionData: formData, permissions, type: "Fill", userId }) : !disabled

  const isCurrentUserAllowedToModify = permissions ? getPermissions({ inspectionData: formData, permissions, type: "Modify", userId }) : !disabled;

  const enabled = isCurrentUserAllowedToFill || isCurrentUserAllowedToModify;

  const getTeamsData=async()=>{
    const res = await teamsService.executeQuery(lookUpQuery);
    const modifiedData = res?.result?.map((team:any) => {
      const data = { id: team._id, name: team.name};
      return data;
    });
    setTeamsData(modifiedData);
  }
  const getLocationData=async()=>{
    const res = await locationService.executeQuery(lookUpQuery);
    const modifiedData = res?.result?.map((team:any) => {
      const data = { id: team._id, name: team.name};
      return data;
    });
    setLocationData(modifiedData);
  }
  useEffect(()=>{
    if(fieldType=="look-up"){
      if(lookUpAnsFieldType=="Teams"){
        getTeamsData()
      }
      else if(lookUpAnsFieldType=="Location"){
        getLocationData()
      }
    }
  },[])

  const getLookUpDropDown = useCallback(
    ({
      selectedValue,
    }) => (
      <div>
        <DropDownTreeComponent
          enabled={enabled}
          width={width}
          showCheckBox
          placeholder={placeholderText ?? ""}
          allowMultiSelection={false}
          value={selectedValue}
          fields={{
            dataSource: locationService,
            value: "_id",
            text: "name",
            parentValue: "parentId",
            hasChildren: "hasChild",
            query: lookUpQuery,
          }}
          select={(args: Object) => {
            handleInputChange({
              args,
              fieldIndex,
              sectionIndex,
              eachField,
              section,
              handleCustomFieldData,
              extraParams,
              tableRowIndex,
              source,
            });
          }}
          name={name}
          showClearButton={false}
        />
      </div>
    ),
    [],
  );

  const handleFileOpen = (awsKey: string) => {
    const url = `${process.env.REACT_APP_CUBE_BACKEND}/fileSystemServer/getInspectionAttachmentFile/${awsKey}`;;
    const target = "_blank";
    return (
      <a href={url} target={target} aria-label="attachment">
        <BiLinkExternal />
      </a>
    );
  };

  const getUserCardAndChip = ({ user }) => {
    return viewType === optionsViewType.CHIP ? (
      !showAnswersOnly ? (
        <UserNameTagComponent
          id={user._id}
          handleDeleteIconClicked={() =>
            removeUserFromList({
              fieldIndex,
              sectionIndex,
              user,
              eachField,
              section,
              source,
              tableRowIndex,
              handleCustomFieldData,
              extraParams
            })
          }
        />
      ) : (
        <UserNameTagComponent id={user._id} />
      )
    ) : (
      <UserCard
        fieldIndex={fieldIndex}
        sectionIndex={sectionIndex}
        section={section}
        source={source}
        tableRowIndex={tableRowIndex}
        showAnswersOnly={showAnswersOnly}
        handleCustomFieldData={handleCustomFieldData}
        extraParams
        removeUserFromList={removeUserFromList}
        field={eachField}
        id={user._id}
      />
    );
  };

  const getFieldAns = () => {
    if (["single-select", "number", "single-line", "multi-line"].includes(fieldType)) {
      return <div className="w-4/5 flex flex-wrap">{currentAnswer.value}</div>;
    }
    if (fieldType == "multi-select") {
      return currentAnswer.value?.join(",");
    }
    if (fieldType == "date-time") {
      return currentAnswer.value
        ? convertDate(Number(currentAnswer.value), "dateForTooltip")
        : "";
    }
    if (fieldType == "look-up") {
      const ansDisplay = currentAnswer.value?.map((ans) => ans.name);
      return ansDisplay?.length ? ansDisplay?.join(",") : "";
    }
    if (fieldType == "users") {
      return (
        <div className="flex flex-wrap gap-5 pt-5">
          {currentAnswer.value?.map((user: Object) =>
            getUserCardAndChip({ user }),
          )}
        </div>
      );
    }
    if (fieldType == "fileUpload") {
      return (
        <span className="flex flex-col pr-3 overflow-auto gap-4 w-full h-full">
          {currentAnswer.value?.map((data: any, index: number) => (
            <span className="flex gap-3 border-solid border-2 border-button_light w-fit pr-2 rounded-lg pl-2 items-center">
              <span>{data.awsKey && handleFileOpen(data.awsKey)}</span>
              <span className="w-14 h-10 flex object-contain">
                {getFileIcon(data.name)}
              </span>
              <span>{data.name}</span>
            </span>
          ))}
        </span>
      );
    }
    if (fieldType == "image") {
      let answerValue = currentAnswer.value
      return (
        <div className="flex flex-wrap flex-row pr-3 overflow-auto gap-4 w-full h-full">
          {answerValue?.map((data: any, index: number) => (
            <span className="w-32 h-32 border-button_light p-1 rounded flex border-2">
              <img
                src={`${process.env.REACT_APP_CUBE_BACKEND}/fileSystemServer/getInspectionAttachmentFile/${data.awsKey}`}
                alt="logo"
                width="100%"
              />
            </span>
          ))}
        </div>
      );
    }
    if (fieldType == "url") {
      return (
        <a target="_blank" href={_.get(currentAnswer, "value.URL", "")} className="text-gradient_color cursor-pointer underline underline-offset-2">
        {_.get(currentAnswer, "value.displayText", "")}
      </a>
      );
    }

    return "";
  };
  
  const getAnswer=(args:any)=>{
    let output : any = {
      answerValue : "",
      status:true
    }
    if(["single-line","multi-line","single-select"].includes(fieldType)){
      output.answerValue = args.target.value?.trim() || null
    }
    else if(fieldType == "multi-select"){
      if (viewType === optionsViewType.LIST) {
        if (args.target.checked) {
          if (currentAnswer?.value && currentAnswer.value.length) {
            output.answerValue = [...currentAnswer.value, args.target.name];
          } else {
            output.answerValue = [args.target.name];
          }
        } else {
          let tempAnswers = [...currentAnswer.value];
          tempAnswers = tempAnswers.filter(
            (ans) => ans != args.target.name,
          );
          output.answerValue = [...tempAnswers];
        }
      } else {
        output.answerValue = args.target.value
      }
    }
    else if(fieldType == "number"){
      output.answerValue = Number(args.target.value.replace(/,/g, ""));
    }
    else if(fieldType == "date-time"){
      if(selectionScope == "currentDateTime"){
        output.answerValue = Date.now();
      }
      else if (
        (minFieldRange && _.get(args,"$d") && new Date(args.value).getTime() < minFieldRange) ||
        (maxFieldRange && _.get(args,"$d") && new Date(args.value).getTime() > maxFieldRange)
      ) {
        output.status = false
        output.error = "Invalid date cannot be saved"
        return
      }
      output.answerValue = _.get(args,"$d") ? new Date(args.$d).getTime() : null;
    }
    else if(fieldType == "look-up"){
      if(lookUpAnsFieldType=="Teams"){
        output.answerValue = args
      }
      else if (lookUpAnsFieldType == "Location") {
        if (args.action == "select") {
          if (currentAnswer.value && currentAnswer.value.length) {
            output.answerValue = [
              ...currentAnswer.value,
              { id: args.itemData.id, name: args.itemData.text },
            ];
          } else {
            output.answerValue = [
              { id: args.itemData.id, name: args.itemData.text },
            ];
          }
        }
        if (args.action == "un-select" && Array.isArray(currentAnswer.value)) {
          let tempAnswers = [...currentAnswer.value];
          tempAnswers = tempAnswers.filter(
            (location) => location.id != args.itemData.id,
          );
          output.answerValue = [...tempAnswers];
        }
      }
    }
    return output
  }
  const handleOnBlur=(args:any)=>{
    const res = getAnswer(args)
    if(res.status){
     if(formData._id){
        handleInputChange({
          answerValue:res.answerValue,
          fieldIndex,
          sectionIndex,
          eachField,
          section,
          tableRowIndex,
          source,
        });
      }
      else{

      }
    }
    else{
      // dispatch(
      //   showToastNotification({
      //     content: res.error,
      //     type:TOAST_TYPE_WARNING
      //   })
      // )
    }
    
    return
  }

  const handleOnChange=(args:any)=>{
    const res = getAnswer(args)
    
    if(res.status){
      if(formValidator.validate){
        formValidator.validate({name,value:res.answerValue})
      }
      if(handleChangeAnswer){
        handleChangeAnswer({
          source,
          answerValue:res.answerValue,
          fieldIndex,
          sectionIndex,
          tableRowIndex
        })
      }
      if (handleCustomFieldData) {
        handleCustomFieldData({
          args: extraParams,
          eachField,
          answerValue:res.answerValue,
          fieldIndex,
          sectionIndex,
          tableRowIndex,
          source,
        });
      }
    }  
  }

  const getFieldAnsComponent = () => {
    let fieldComponent = <></>
    let showCustomValidation = false
    if (fieldType == "single-line") {
      fieldComponent = 
        <TextFieldComponent
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          disabled={!enabled}
          error={!!_.get(formValidator,`error.${name}`)}
          value={currentAnswer.value ?? undefined}
          label={placeholderText ?? ""}
          width={width}
        />
      
    }
    if (fieldType == "multi-line") {
        fieldComponent =     
          <textarea
            style={{
              border: "1px solid #19204880",
              outline: "none",
              width: source == formSectionType.FORM ? "80%" : "100%",
              padding: "0.42rem 0 0.42rem 0.5rem",
              height: "100%",
              color: "black",
            }}
            error={!!_.get(formValidator,`error.${name}`)}
            type="textarea"
            disabled={!enabled}
            rows={multiTextRows}
            name={name}
            placeholder={placeholderText ?? ""}
            defaultValue={currentAnswer.value ?? ""}
            onBlur={handleOnBlur}
            onChange={handleOnChange}
          />
    }
    if (fieldType == "single-select") {
      if(viewType == optionsViewType.LIST){
        fieldComponent = 
        <RadioGroup
        error={!!_.get(formValidator,`error.${name}`)}
          value={currentAnswer.value}
          onChange={(args: any) => {
            handleOnBlur(args)
            handleOnChange(args)
          }}
        >
          {answerChoices.map((choice: string, index: number) => (
            <FormControlLabel disabled={!enabled} value={choice} control={<RadioButton />} label={choice} />
          ))}
        </RadioGroup>
       
      }
      else{
        fieldComponent = <AutoComplete
        disabled={!enabled}
        error={!!_.get(formValidator,`error.${name}`)}
        value={currentAnswer.value ?? undefined}
         onChange={(event:any, newValue:any) => {
           handleOnChange({target:{value:newValue}})
           handleOnBlur({target:{value:newValue}})
         }}
         id={name}
         options={answerChoices}
         sx={{ width }}
         renderInput={(params:any) => <TextFieldComponent {...params} label={placeholderText??""} />}
       />
      }
    }
    if (fieldType == "multi-select") {
       if(viewType == optionsViewType.LIST){
        fieldComponent =  
        <FormGroup>
        {answerChoices?.length > 0 && answerChoices.map((choice: string, index: number) => (
          <FormControlLabel control={<CheckBoxComponent onChange={(args:any)=>{
            handleOnBlur(args)
            handleOnChange(args)
          }} name={choice} checked={Array.isArray(currentAnswer.value) && currentAnswer.value.includes(choice)} />} label={choice} />
        ))}
        </FormGroup>
       }
       else{
          fieldComponent =  <AutoComplete
          disabled={!enabled}
          multiple
          disableCloseOnSelect
          error={!!_.get(formValidator,`error.${name}`)}
          value={currentAnswer.value ?? undefined}
          onChange={(event: any, newValue: any) => {
            handleOnChange({ target: { value: newValue } })
            handleOnBlur({ target: { value: newValue } })
          }}
          id={name}
          options={answerChoices}
          sx={{ width }}
          renderInput={(params: any) => <TextFieldComponent {...params} label={placeholderText ?? ""} />}
        />
       } 
    }
    if (fieldType == "date-time") {
      const min = minFieldRange? minFieldRange : null
      const max = maxFieldRange? maxFieldRange : null;

      let extraProps = {}
      if(selectionScope === "pastDateTime"){
        extraProps = {
          disableFuture : true
        }
      }
      else if(selectionScope === "futureDateTime"){
        extraProps = {
          disablePast : (!currentAnswer.value && selectionScope === "futureDateTime")
        }
      }
      else if (selectionScope === "rangeBased") {
        extraProps = {
          minDate: min,
          maxDate: max
        }
      }

      if(selectionScope == "currentDateTime"){
        fieldComponent = (
          enabled ? (
            <div
              className={`${currentAnswer?.value
                ? "cursor-not-allowed pointer-events-none"
                : "cursor-pointer"
                } border border-slate-400 w-1/2 h-8 flex items-center p-2`}
              onClick={(args: Object) => {
                handleOnBlur(args)
                handleOnChange(args)
              }}
            >
              {currentAnswer?.value ? (
                convertDate(Number(currentAnswer?.value), "dateForTooltip")
              ) : (
                <span className="text-xs text-slate-500">
                  Click here to set date
                </span>
              )}
            </div>
          ) : (
            <div className="cursor-not-allowed pointer-events-none border bg-gray-100 border-slate-400 w-1/2 h-8 flex items-center p-2">
              {currentAnswer?.value &&
                convertDate(Number(currentAnswer?.value), "dateForTooltip")}
            </div>
          )
        )
      }
      else{
        fieldComponent = 
        <DateTimeComponent
        slotProps={{
            field: { clearable: true }
        }}
        {...extraProps}
        sx={{ width }} 
        format="DD/MM/YYYY hh:mm:a"
        error={!!_.get(formValidator,`error.${name}`)}
        label={placeholderText ?? ""}
        value={currentAnswer.value ??null}
        onChange={(args:any) => {
          handleOnBlur(args)
          handleOnChange(args)
        }}
      />
      }
    }
    if (fieldType == "look-up") {
      if (lookUpAnsFieldType == "Location") {
        showCustomValidation = true
        const selectedValue =
          (Array.isArray(currentAnswer.value) && currentAnswer.value?.map((ans) => ans.id)) ||
          [];
          fieldComponent =  getLookUpDropDown({
          source,
          placeholderText,
          selectedValue,
          fieldIndex,
          sectionIndex,
          eachField,
          section,
          tableRowIndex,
          name,
        });
      }
      if (lookUpAnsFieldType == "Teams") {
        fieldComponent =  (
          <AutoComplete
            disabled={!enabled}
            multiple
            error={!!_.get(formValidator,`error.${name}`)}
            disableCloseOnSelect
            getOptionLabel={(option: any) => option.name}
            value={currentAnswer.value ?? []}
            onChange={(event: any, newValue: any) => {
              handleOnChange(newValue)
              handleOnBlur(newValue)
            }}
            id={name}
            options={teamsData}
            sx={{ width }}
            renderInput={(params: any) => <TextFieldComponent {...params} label={placeholderText ?? ""} />}
          />
        )
      }
    }
    if (fieldType == "number") {
        fieldComponent = 
          <TextFieldComponent
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            error={!!_.get(formValidator,`error.${name}`)}
            inputProps={{
              min: minFieldRange ? +minFieldRange : 0,
              max: maxFieldRange ? +maxFieldRange : null,
            }}
            disabled={!enabled}
            value={currentAnswer.value ?? undefined}
            label={placeholderText ?? ""}
            width={width}
            type="number"
          />
    }
    if (fieldType == "users") {
      showCustomValidation = true
      fieldComponent = (
        <div>
          {(enabled) && (
            <div
              onClick={() =>
                openAddUserModal({
                  eachField,
                  fieldIndex,
                  sectionIndex,
                  section,
                  source,
                  tableRowIndex,
                  handleCustomFieldData,
                  extraParams
                })
              }
              className="bg-button_light h-8 w-14 rounded-3xl justify-center flex cursor-pointer items-center"
            >
              <IconComponent name="UserProfile" />
            </div>
          )}
          <div className="flex flex-wrap gap-5 overflow-auto pt-5">
            {currentAnswer.value?.map((user: Object) =>
              getUserCardAndChip({ user })
            )}
          </div>
        </div>
      );
    }
    if (fieldType == "fileUpload") {
      showCustomValidation = true
      fieldComponent =  (
        <div className="flex h-full w-full overflow-auto flex-col gap-5">
          <span>
            <input
              accept={eachField.allowedFileTypes.join(",")}
              type="file"
              id={`${questionId}${tableRowIndex}`}
              onChange={(e) =>
                handleFileUploadChange({
                  e,
                  sectionId,
                  eachField,
                  sectionIndex,
                  fieldIndex,
                  source,
                  tableRowIndex,
                  tableRowId,
                  allowedFileTypes: eachField.allowedFileTypes,
                  formData,
                  handleCustomFieldData,
                  extraParams
                })
              }
              style={{ display: "none" }}
            />
            <ButtonComponent
              onClick={() =>
                document.getElementById(`${questionId}${tableRowIndex}`).click()
              }
              disabled={!enabled}
              buttonType={BUTTON_TYPES.BACKGROUND_BUTTON}
            >
              <span className="flex gap-1 items-center">
                <PlusIcon
                  color={
                    enabled
                      ? "var(--primary-text)"
                      : "var(--system-colour-37"
                  }
                />
                <span>{placeholderText}</span>
              </span>
            </ButtonComponent>
          </span>
          <span className="flex flex-col pr-3 overflow-auto gap-4 w-full h-full">
            {currentAnswer.value?.map((data: any, index: number) => (
              <span className="flex gap-3 border-solid border-2 border-button_light w-fit pr-2 rounded-lg pl-2 items-center">
                <span>{data.awsKey && handleFileOpen(data.awsKey)}</span>
                <span className="w-14 h-10 flex object-contain">
                  {" "}
                  {getFileIcon(data.name)}
                </span>
                <span>{data.name}</span>
                {enabled && <span className="w-8">
                  <IconComponent
                    cssClass="cursor-pointer"
                    onClick={() =>
                      handleRemoveUploadedFile({
                        awsKey: data.awsKey,
                        sectionIndex,
                        fieldIndex,
                        sectionId,
                        questionId,
                        tableRowIndex,
                        source,
                        handleCustomFieldData,
                        extraParams})
                    }
                    name="close"
                  />
                </span>}
              </span>
            ))}
            {isFileUploading.type == "fileUpload" &&
              (!tableRowId ||
                (tableRowId && isFileUploading.tableRowId == tableRowId)) && (
                <span className="flex gap-3 border-solid border-2 border-button_light w-fit pr-2 rounded-lg pl-2 items-center justify-center">
                  <CircularProgress />
                </span>
              )}
          </span>
        </div>
      );
    }
    if (fieldType == "image") {
      showCustomValidation = true
      fieldComponent =  (
        <div className="flex h-full w-full overflow-auto flex-col gap-5">
          <span>
            <input
              accept={eachField.allowedFileTypes.join(",")}
              type="file"
              id={`${questionId}${tableRowIndex}`}
              onChange={(e) =>
                handleFileUploadChange({
                  e,
                  sectionId,
                  eachField,
                  sectionIndex,
                  fieldIndex,
                  source,
                  tableRowIndex,
                  tableRowId,
                  allowedFileTypes: eachField.allowedFileTypes,
                  formData,
                  handleCustomFieldData,
                  extraParams
                })
              }
              style={{ display: "none" }}
            />
            <ButtonComponent
              onClick={() =>
                document.getElementById(`${questionId}${tableRowIndex}`).click()
              }
              disabled={!enabled}
              buttonType={BUTTON_TYPES.BACKGROUND_BUTTON}
            >
              <span className="flex gap-1 items-center">
                <PlusIcon
                  color={
                    enabled
                      ? "var(--primary-text)"
                      : "var(--system-colour-37"
                  }
                />
                <span>{placeholderText}</span>
              </span>
            </ButtonComponent>
          </span>
          <span className="flex flex-wrap flex-row pr-3 overflow-auto gap-4 w-full h-full">
            {currentAnswer.value?.map((data: any, index: number) => (
              <span className="w-32 h-32 border-button_light p-1 rounded flex border-2">
                <ImageEditor
                  isEditable={enabled}
                  imageData={`${process.env.REACT_APP_CUBE_BACKEND}/fileSystemServer/getInspectionAttachmentFile/${data.awsKey}`}
                  onSubmitFunction={(base64EncodedImg: string) =>
                    saveEditedImage({
                      base64EncodedImg,
                      handleCustomFieldData,
                      extraParams,
                      awsKey: data.awsKey,
                      sectionId,
                      questionId,
                    })
                  }
                  heading="Edit Image"
                  onDelete={() =>
                    handleRemoveUploadedFile({
                      awsKey: data.awsKey,
                      sectionIndex,
                      fieldIndex,
                      sectionId,
                      questionId,
                      source,
                      tableRowIndex,
                      handleCustomFieldData
                    })
                  }
                />
              </span>
            ))}
            {isFileUploading.type == "image" &&
              (!tableRowId ||
                (tableRowId && isFileUploading.tableRowId == tableRowId)) && (
                <span className="w-32 h-32 border-button_light p-1 items-center justify-center rounded flex border-2">
                  <CircularProgress />
                </span>
              )}
          </span>
        </div>
      );
    }
    if (fieldType == "url") {
      showCustomValidation = true
      fieldComponent =  (
        <div className="flex h-full w-full overflow-auto flex-col gap-5">
          {currentAnswer?.value?.displayText ? (
            <span className="flex items-center gap-3">
              <a target="_blank" href={_.get(currentAnswer, "value.URL", "")} className="text-gradient_color cursor-pointer underline underline-offset-2">
                {_.get(currentAnswer, "value.displayText", "")}
              </a>
              <ButtonComponent
                disabled={!enabled}
                onClick={() => {
                  handleURLModalState({
                    state: true,
                    sectionIndex,
                    eachField,
                    tableRowIndex,
                    answer:currentAnswer,
                    fieldIndex,
                    sectionId,
                    source,
                  });

                }}
              >
                <IconComponent name="editProfile" cssClass="h-5" />
              </ButtonComponent>
            </span>
          ) : (
            <span>
              <ButtonComponent
                disabled={!enabled}
                onClick={() =>
                  handleURLModalState({
                    state: true,
                    sectionIndex,
                    eachField,
                    tableRowIndex,
                    answer:currentAnswer,
                    fieldIndex,
                    sectionId,
                    source,
                  })
                }
                buttonType={BUTTON_TYPES.BACKGROUND_BUTTON}
              >
                <span className="flex gap-1 items-center">
                  <IconComponent
                    name="URL"
                    cssClass={`h-5 ${enabled ? "" : "opacity-50"}`}
                  />
                  <span>Enter URL</span>
                </span>
              </ButtonComponent>
            </span>
          )}
        </div>
      );
    }
    return showCustomValidation ? (
      (
        <ValidationWrapperWeb value={currentAnswer.value} name={name} validator={formValidator} >
          {fieldComponent}
        </ValidationWrapperWeb>
      )
    ) : (
      <FormControl fullWidth error={!!_.get(formValidator,`error.${name}`)}>
        {fieldComponent}
        {(!!_.get(formValidator,`error.${name}`)) && <FormHelperText sx={{ marginLeft: 0 }}>{_.get(formValidator,`error.${name}`)}</FormHelperText>}
      </FormControl>
    )
  };

  return (
    <>
      {showAnswersOnly ? getFieldAns() : getFieldAnsComponent()}
    </>
  )
}
export default FormQuestionFields