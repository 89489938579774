import IconComponent from "@CubeComponents/icons";
import Accordion from "@CubeComponents/html/accordion/materialUiAccordian/index";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import ButtonComponent from "@CubeComponents/form/ButtonComponent";
import { BUTTON_TYPES } from "@CubeComponents/constants/globalVariable";
import {
  DeleteIcon,
  PlusIcon,
} from "@Components/StickySidebarViewer/assets/selectionIcons";
import { getIconFromFieldType } from "./constants";
import { presetSectionsCodeObject } from "../constants";
import {
  addValidationToForms,
  formSectionType,
} from "../../../../CreateInspectionAndProgress/constants";
import { v4 as uuid } from "uuid";
import { useSelector } from "react-redux";
import _ from "lodash";
import FormQuestionFields from "../../../../CreateInspectionAndProgress/questionFields";
import AddURLModal from "../../../../CreateInspectionAndProgress/addURLModal";
import { useNavigate } from "react-router-dom";
import useForms from "../../../../CreateInspectionAndProgress/useForms";
import { forwardRef, useEffect, useRef } from "react";
import useFormValidator from "../../../../FormValidation/useFormValidator";

const FormPreview = forwardRef(
  (
    {
      formData = { template: [] },
      setFormData,
      disabled = true,
      allowPadding = true,
      handleData,
      allowBorder = true,
      headingFontSize = "text-xl",
      defaultSection,
      showInGrid = false,
      showInSidebar = false,
      saveCustomFieldsData,
      projectId,
      fullWidthInput = false,
      removeIcons = false,
      showNewFields = false,
      showAnswersOnly = false,
      handleSaveCustomFields,
      setUpdateTemplateAfterUse,
      formValidator,
    },
    ref,
  ) => {
    if (!formValidator) {
      formValidator = useFormValidator({});
    }
    const showFullInput = showInGrid || showInSidebar || fullWidthInput;
    const userDetails = useSelector((state) => state.userDetails);

    ref = ref ?? useRef(null);

    const navigate = useNavigate();

    const {
      handleInputChange,
      handleURLModalState,
      isFileUploading,
      handleFileUploadChange,
      removeUserFromList,
      saveEditedImage,
      handleRemoveUploadedFile,
      openAddUserModal,
      setURLData,
      showUrlModal,
      handleSaveUrlData,
    } = useForms({ navigate });

    const showFields = (fieldType) => {
      if (["users", "fileUpload", "image"].includes(fieldType)) {
        return showNewFields;
      }
      return true;
    };

    useEffect(() => {
      addValidationToForms({
        formValidator,
        template: formData?.template,
      });
    }, []);

    const handleCustomFieldData = ({
      eachField,
      answerValue,
      fieldIndex,
      sectionIndex,
      tableRowIndex,
      source,
    }) => {
      const { _id } = eachField;
      const modifiedTemplate = _.cloneDeep(formData?.template);
      if (source == formSectionType.TABLE_SECTION) {
        const modifiedTableDataSource = modifiedTemplate[
          sectionIndex
        ].tableDataSource.map((row, index) => {
          if (index == tableRowIndex) {
            row[_id] = {
              createdDate: Date.now(),
              createdBy: userDetails._id,
              value: answerValue,
            };
          }
          return row;
        });
        modifiedTemplate[sectionIndex].tableDataSource =
          modifiedTableDataSource;

        setUpdateTemplateAfterUse((prev) => ({
          ...prev,
          CONFIGURE_SECTION: [
            ...prev.CONFIGURE_SECTION,
            {
              fieldType: "tableDataSource",
              tableDataSource: modifiedTableDataSource,
              sectionId: formData.template[sectionIndex]._id,
            },
          ],
        }));
      } else if (
        modifiedTemplate[sectionIndex].questionFields[fieldIndex].answer
      ) {
        modifiedTemplate[sectionIndex].questionFields[fieldIndex].answer = {
          ...modifiedTemplate[sectionIndex].questionFields[fieldIndex].answer,
          value: answerValue,
        };
      } else {
        modifiedTemplate[sectionIndex].questionFields[fieldIndex].answer = {
          value: answerValue,
        };
      }
      if (handleData) {
        handleData(modifiedTemplate);
      }

      if (setFormData) {
        setFormData((prev) => ({
          ...prev,
          template: modifiedTemplate,
        }));
      }
      if (handleSaveCustomFields) {
        const fieldsData = [
          {
            _id,
            value: answerValue,
          },
        ];
        handleSaveCustomFields(fieldsData, modifiedTemplate);
      }
    };

    const previewSectionHeader = (index, data, isDefaultSection = false) => (
      <div
        className={`flex justify-between w-full ${
          allowPadding ? "pl-4 pr-4" : ""
        } py-3 ${
          allowBorder ? "border-b border-button_light" : ""
        } items-center`}
      >
        <div className="flex gap-2 items-center">
          <span className={`${headingFontSize} font-semibold`}>
            {data.sectionHeading}
          </span>
          {isDefaultSection && (
            <span className="rounded-full text-xxs bg-gray-500 text-white px-2 py-0.5">
              Default
            </span>
          )}
        </div>
        <span>
          <IconComponent name="arrowDown" cssClass="cursor-pointer" />
        </span>
      </div>
    );

    const gridPreviewSectionContent = (data, sectionIndex) => (
      <div className="grid grid-cols-2 gap-20 w-full px-2">
        {data.questionFields?.map(
          (fields, index) =>
            showFields(fields.fieldType) && (
              <div key={index} className="flex">
                <div className="flex">
                  <div
                    className="text-base font-semibold"
                    data-testid="projectId"
                  >
                    {fields.questionValue}
                  </div>
                  {fields.isMandatory && (
                    <span className="text-2xl align-middle font-light text-red-500">
                      *
                    </span>
                  )}
                </div>
                <div className="w-3/4 ml-auto">
                  <FormQuestionFields
                    formValidator={formValidator}
                    showAnswersOnly={showAnswersOnly}
                    eachField={fields}
                    fieldIndex={index}
                    sectionIndex={sectionIndex}
                    section={data}
                    handleCustomFieldData={handleCustomFieldData}
                    disabled={disabled}
                    width={showFullInput ? "100%" : "30%"}
                    formData={{ selectedForm: formData }}
                    handleInputChange={handleInputChange}
                    handleURLModalState={handleURLModalState}
                    isFileUploading={isFileUploading}
                    handleFileUploadChange={handleFileUploadChange}
                    removeUserFromList={removeUserFromList}
                    saveEditedImage={saveEditedImage}
                    handleRemoveUploadedFile={handleRemoveUploadedFile}
                    openAddUserModal={openAddUserModal}
                  />
                </div>
              </div>
            ),
        )}
      </div>
    );

    const sidebarPreviewSectionContent = (data, sectionIndex) => (
      <div className="flex flex-col gap-4 w-full px-2">
        {data.questionFields?.map(
          (fields, index) =>
            showFields(fields.fieldType) && (
              <div key={index} className="flex">
                <div className="flex gap-1 flex-1">
                  <div
                    className="text-sm font-semibold"
                    data-testid="projectId"
                  >
                    {fields.questionValue}
                  </div>
                  {fields.isMandatory && (
                    <span className="text-2xl align-middle font-light text-red-500">
                      *
                    </span>
                  )}
                </div>
                <div className="flex-1">
                  <FormQuestionFields
                    formValidator={formValidator}
                    showAnswersOnly={showAnswersOnly}
                    eachField={fields}
                    fieldIndex={index}
                    sectionIndex={sectionIndex}
                    section={data}
                    handleCustomFieldData={handleCustomFieldData}
                    disabled={disabled}
                    width={showFullInput ? "100%" : "30%"}
                    formData={{ selectedForm: formData }}
                    handleInputChange={handleInputChange}
                    handleURLModalState={handleURLModalState}
                    isFileUploading={isFileUploading}
                    handleFileUploadChange={handleFileUploadChange}
                    removeUserFromList={removeUserFromList}
                    saveEditedImage={saveEditedImage}
                    handleRemoveUploadedFile={handleRemoveUploadedFile}
                    openAddUserModal={openAddUserModal}
                  />
                </div>
              </div>
            ),
        )}
      </div>
    );

    const defaultPreviewSectionContent = (data, sectionIndex) => (
      <div className={`flex flex-col gap-8 ${allowPadding ? "pl-3" : ""}`}>
        {data.questionFields?.map(
          (fields, index) =>
            showFields(fields.fieldType) && (
              <div key={index} className="flex flex-col gap-3">
                <div className="flex gap-3">
                  {!removeIcons && (
                    <span className="w-6 flex justify-start items-center flex-col gap-1">
                      <span>{getIconFromFieldType(fields.fieldType)}</span>
                    </span>
                  )}
                  <span className="flex w-11/12 flex-col break-words gap-1">
                    <span className="flex">
                      {/* <span className="font-semibold">
                        {fields.questionValue}
                      </span> */}
                      <div
                        className="text-base font-semibold"
                        data-testid="projectId"
                      >
                        {`${fields.questionValue}`}{" "}
                      </div>
                      {fields.isMandatory && (
                        <span className="text-2xl align-middle font-light text-red-500">
                          *
                        </span>
                      )}
                    </span>
                    {fields.fieldInstruction && (
                      <span className="text-gray-400 w-full text-xs">
                        {fields.fieldInstruction
                          ? `instructions : ${fields.fieldInstruction}`
                          : ""}
                      </span>
                    )}
                  </span>
                </div>
                <div className={allowPadding && "pl-8"}>
                  {fields.isVaries && (
                    <span className="text-red-500 italic mr-1">Varies</span>
                  )}
                  <FormQuestionFields
                    formValidator={formValidator}
                    showAnswersOnly={showAnswersOnly}
                    eachField={fields}
                    fieldIndex={index}
                    sectionIndex={sectionIndex}
                    section={data}
                    handleCustomFieldData={handleCustomFieldData}
                    disabled={disabled}
                    width={showFullInput ? "100%" : "30%"}
                    formData={{ selectedForm: formData }}
                    handleInputChange={handleInputChange}
                    handleURLModalState={handleURLModalState}
                    isFileUploading={isFileUploading}
                    handleFileUploadChange={handleFileUploadChange}
                    removeUserFromList={removeUserFromList}
                    saveEditedImage={saveEditedImage}
                    handleRemoveUploadedFile={handleRemoveUploadedFile}
                    openAddUserModal={openAddUserModal}
                  />
                </div>
              </div>
            ),
        )}
      </div>
    );

    const handleAddTableRowClick = ({ section, sectionIndex }) => {
      if (setFormData) {
        const newObjRow = {
          rowId: uuid(),
          isPreFilledRow: true,
        };
        formData.template[sectionIndex].questionFields.map((field) => {
          newObjRow[field._id] = {};
        });
        const modifiedTemplate = [...formData.template];

        let newTableDataSource = [];
        if (modifiedTemplate[sectionIndex].tableDataSource) {
          newTableDataSource = [
            ...modifiedTemplate[sectionIndex].tableDataSource,
            newObjRow,
          ];
        } else {
          newTableDataSource = [newObjRow];
        }
        modifiedTemplate[sectionIndex].tableDataSource = newTableDataSource;

        setFormData((prev) => ({
          ...prev,
          template: modifiedTemplate,
        }));

        setUpdateTemplateAfterUse((prev) => ({
          ...prev,
          CONFIGURE_SECTION: [
            ...prev.CONFIGURE_SECTION,
            {
              fieldType: "tableDataSource",
              tableDataSource: newTableDataSource,
              sectionId: formData.template[sectionIndex]._id,
            },
          ],
        }));
      }
    };

    const handleDeleteTableRow = ({ section, sectionIndex, rowIndex }) => {
      if (setFormData) {
        const modifiedTemplate = [...formData.template];

        const dataSource = modifiedTemplate[sectionIndex].tableDataSource;

        dataSource.splice(rowIndex, 1);

        modifiedTemplate[sectionIndex].tableDataSource = [...dataSource];

        setFormData((prev) => ({
          ...prev,
          template: modifiedTemplate,
        }));

        setUpdateTemplateAfterUse((prev) => ({
          ...prev,
          CONFIGURE_SECTION: [
            ...prev.CONFIGURE_SECTION,
            {
              fieldType: "tableDataSource",
              tableDataSource: [...dataSource],
              sectionId: formData.template[sectionIndex]._id,
            },
          ],
        }));
      }
    };

    const getTableSectionGrid = (data, sectionIndex) => {
      const dataSource = data.tableDataSource || [];
      return (
        <span className="overflow-auto flex flex-col gap-4 w-full">
          <span>
            {setFormData && (
              <ButtonComponent
                disabled={!data.allowPreDefineRowValues}
                onClick={() =>
                  handleAddTableRowClick({ section: data, sectionIndex })
                }
                buttonType={BUTTON_TYPES.BACKGROUND_BUTTON}
              >
                Add Row
              </ButtonComponent>
            )}
          </span>
          <table className="w-full overflow-auto flex flex-col h-full">
            <tr className="h-full w-full flex justify-normal text-left">
              {dataSource.length > 1 && (
                <th className="p-4 text-sm border-2 w-14 bg-button_light border-button_light">
                  .....
                </th>
              )}
              <th className="p-4 text-sm border-2 w-14 bg-button_light border-button_light">
                S.No
              </th>
              {data.questionFields.map((field) => (
                // <ToolTipComponent content={field.questionValue} position="TopCenter">
                <th className="p-4 items-center flex text-sm border-2 border-button_light w-full min-w-60 bg-button_light whitespace-nowrap overflow-hidden text-ellipsis">
                  {field.isMandatory && (
                    <span className="text-2xl font-light text-red-500">*</span>
                  )}
                  <span>{`${field.questionValue}`}</span>
                </th>
                //  </ToolTipComponent>
              ))}
            </tr>
            {dataSource.map((obj, index) => (
              <tr className="h-full w-full flex justify-normal text-left">
                {dataSource.length > 1 && (
                  <td className="p-4 min-w-14 max-w-40 border-2 border-button_light">
                    <IconComponent
                      onClick={() =>
                        handleDeleteTableRow({
                          section: data,
                          sectionIndex,
                          rowIndex: index,
                        })
                      }
                      cssClass="cursor-pointer"
                      name="delete"
                    />
                  </td>
                )}
                <td className="p-4 min-w-14 max-w-40 border-2 border-button_light">
                  {index + 1}
                </td>
                {data.questionFields.map((field, fieldIndex) => (
                  <td className="p-4 min-w-60 max-h-60 overflow-auto w-full border-2 border-button_light">
                    <FormQuestionFields
                      formValidator={formValidator}
                      showAnswersOnly={showAnswersOnly}
                      eachField={field}
                      fieldIndex={index}
                      sectionIndex={sectionIndex}
                      section={data}
                      handleCustomFieldData={handleCustomFieldData}
                      disabled={
                        !(
                          field.allowPreDefinedColValues &&
                          !!setFormData &&
                          data.allowPreDefineRowValues
                        )
                      }
                      tableRowId={data.tableDataSource[index]?.rowId}
                      tableRowIndex={index}
                      width="100%"
                      source={formSectionType.TABLE_SECTION}
                      formData={{ selectedForm: formData }}
                      handleInputChange={handleInputChange}
                      handleURLModalState={handleURLModalState}
                      isFileUploading={isFileUploading}
                      handleFileUploadChange={handleFileUploadChange}
                      removeUserFromList={removeUserFromList}
                      saveEditedImage={saveEditedImage}
                      handleRemoveUploadedFile={handleRemoveUploadedFile}
                      openAddUserModal={openAddUserModal}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </table>
        </span>
      );
    };

    const previewSectionContent = (sectionIndex, data) => {
      if (
        ["References", "Quantities", "WBS Items", "Forms"].includes(
          data.sectionHeading,
        ) &&
        data.sectionCode
      ) {
        return (
          <div className="flex items-center gap-2">
            <ButtonComponent
              buttonType={BUTTON_TYPES.BACKGROUND_BUTTON}
              disabled={disabled}
            >
              <div className="flex items-center">
                <PlusIcon color="var(--system-colour-37" />
                <span>Add</span>
              </div>
            </ButtonComponent>
            <ButtonComponent
              buttonType={BUTTON_TYPES.TEXT_BUTTON}
              disabled={disabled}
            >
              <div className="flex items-center">
                <DeleteIcon color="var(--system-colour-37" />
                <span>Remove</span>
              </div>
            </ButtonComponent>
          </div>
        );
      }
      if (data.sectionHeading == "Tasks" && data.sectionCode) {
        return (
          <div className="flex flex-col gap-5 p-3">
            <span className="flex gap-3 items-center">
              Actual Start Date
              <DateTimePickerComponent
                name="actualStartDate"
                width="400px"
                disabled
                id="actualStartDate"
                placeholder="Select Date"
              />
            </span>
            <span className="flex gap-3 items-center">
              Actual End Date
              <DateTimePickerComponent
                disabled
                name="actualEndDate"
                width="400px"
                id="actualEndDate"
                placeholder="Select Date"
              />
            </span>
            <span className="flex gap-3 items-center">
              Progress %
              <NumericTextBoxComponent width="400px" disabled />
            </span>
            <span className="flex gap-3 items-center">
              Remaining Duration
              <NumericTextBoxComponent width="400px" disabled />
            </span>
            <div className="flex items-center gap-2">
              <ButtonComponent
                buttonType={BUTTON_TYPES.BACKGROUND_BUTTON}
                disabled
              >
                <div className="flex items-center">
                  <PlusIcon color="var(--system-colour-37)" />
                  <span>Add</span>
                </div>
              </ButtonComponent>
              <ButtonComponent buttonType={BUTTON_TYPES.TEXT_BUTTON} disabled>
                <div className="flex items-center">
                  <DeleteIcon color="var(--system-colour-37)" />
                  <span>Remove</span>
                </div>
              </ButtonComponent>
            </div>
          </div>
        );
      }

      if (data.sectionHeading == "Milestones" && data.sectionCode) {
        return (
          <div className="flex flex-col gap-5">
            <div className="flex gap-5 p-3 items-center">
              Milestone Completion Date
              <DateTimePickerComponent
                width="400px"
                name="MilestoneCompletionDate"
                disabled
                id="MilestoneCompletionDate"
                placeholder="Select Date"
              />
            </div>
            <div className="flex gap-3 items-center p-3">
              Milestone Amount
              <NumericTextBoxComponent
                name="MilestoneAmount"
                width="400px"
                disabled
                id="MilestoneAmount"
                placeholder="Enter Amount"
              />
            </div>
          </div>
        );
      }
      if (data.sectionCode == presetSectionsCodeObject.tableSection) {
        return getTableSectionGrid(data, sectionIndex);
      }
      if (showInGrid) {
        return gridPreviewSectionContent(data, sectionIndex);
      }
      if (showInSidebar) {
        return sidebarPreviewSectionContent(data, sectionIndex);
      }
      return defaultPreviewSectionContent(data, sectionIndex);
    };

    return (
      <div
        className="flex flex-col w-full h-full gap-2 overflow-auto"
        id="formData"
      >
        {defaultSection && (
          <Accordion
            defaultExpanded
            key="deafult-section-accordian"
            border={allowBorder ? "1px solid var(--primary-blue)" : ""}
            borderRadius="10px"
          >
            <AccordionSummary>
              {previewSectionHeader(
                formData.template?.length,
                defaultSection,
                true,
              )}
            </AccordionSummary>
            <AccordionDetails>
              {previewSectionContent(
                formData?.template?.length,
                defaultSection,
              )}
            </AccordionDetails>
          </Accordion>
        )}
        {formData?.template?.length > 0 &&
          formData?.template.map((data, index) => (
            <Accordion
              defaultExpanded
              key={data._id}
              border={allowBorder ? "1px solid var(--primary-blue)" : ""}
              borderRadius="10px"
              sx={
                !allowPadding && {
                  "& .MuiAccordionDetails-root": {
                    height: "100%",
                    padding: "0",
                  },
                }
              }
            >
              <AccordionSummary>
                {previewSectionHeader(index, data)}
              </AccordionSummary>
              <AccordionDetails>
                {previewSectionContent(index, data)}
              </AccordionDetails>
            </Accordion>
          ))}

        <AddURLModal
          handleCustomFieldData={handleCustomFieldData}
          setURLData={setURLData}
          showUrlModal={showUrlModal}
          handleURLModalState={handleURLModalState}
          handleSaveUrlData={handleSaveUrlData}
        />
      </div>
    );
  },
);
export default FormPreview;
