import axiosWrapper from "@Services/axiosWrapper";
import { getBackendUrl } from "@Utils";

export function setCustomModules(data) {
  return {
    type: "CUSTOM_MODULES",
    data,
  };
}

export function getPortalCustomModules(data) {
  return async (dispatch) => {
    const responseData = await axiosWrapper.post(
      `${getBackendUrl()}/portal/customModuleController`,
    );
    if (responseData.data.status) {
      dispatch(setCustomModules(responseData.data.result));
    }
  };
}
