import { forwardRef, useRef } from "react";
import TextField from '@mui/material/TextField';
import React from 'react';

const TextFieldComponent = forwardRef((props: any, ref: any) => {

    const textRef = ref || useRef();

    return (
        <TextField
            {...props}
            ref={textRef}
            sx={{
                width: props.width??"100%",
                "& .MuiOutlinedInput-input": {
                  padding: "8px",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "var(--info-light-blue)",
                    borderWidth: "1px",
                  },
                },
            }}
        />
    )
})
export default TextFieldComponent