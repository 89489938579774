import React from 'react';
import Radio from '@mui/material/Radio';
import { forwardRef, useRef } from "react";

const RadioButton = forwardRef((props: any, ref: any) => {

    const btnRef = ref || useRef();

    return (
        <Radio
            {...props}
            ref={btnRef}
        />
    )
})
export default RadioButton