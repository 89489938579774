import axiosWrapper from "@Services/axiosWrapper";
import { getBackendUrl } from "@Utils";

export function setContractDetails(data) {
  return {
    type: "SET_CONTRACT_DETAILS",
    data,
  };
}
export async function contractsController(data) {
  const response = await axiosWrapper.post(
    `${getBackendUrl()}/contracts/contractsController`,
    data,
  );
  return response;
}
export function getContractDetails(params) {
  return async (dispatch) => {
    const data = {
      value: {
        _id: params.contractId,
        versionId: params.versionId,
      },
    };
    const response = await axiosWrapper.post(
      `${getBackendUrl()}/contracts/contractsController`,
      data,
    );

    if (response.data.result) {
      dispatch(setContractDetails(response.data.result[0]));
    }
  };
}
export const linkEntitiesToContract = async (data) => {
  const response = await axiosWrapper.post(
    `${getBackendUrl()}/contracts/linkEntitiesToContract`,
    data,
  );
  return response;
};
export const lockContractEntity = async (data) => {
  const response = await axiosWrapper.post(
    `${getBackendUrl()}/contracts/lockContractEntity`,
    data,
  );
  return response;
};
export const getContractItemDetails = async (data) => {
  const response = await axiosWrapper.post(
    `${getBackendUrl()}/contracts/getContractItemDetails`,
    data,
  );
  return response;
};
export const mileStoneController = async (data) => {
  const response = await axiosWrapper.post(
    `${getBackendUrl()}/contracts/mileStoneController`,
    data,
  );
  return response;
};
export const saveContractAmount = async (data) => {
  const response = await axiosWrapper.post(
    `${getBackendUrl()}/contracts/saveContractAmount`,
    data,
  );
  return response;
};
export const getFormMileStones = async (data) => {
  const response = await axiosWrapper.post(
    `${getBackendUrl()}/contracts/getFormMileStones`,
    data,
  );
  return response;
};
export const updateMilestonePreference = async (data) => {
  const response = await axiosWrapper.post(
    `${getBackendUrl()}/contracts/updateMilestonePreference`,
    data,
  );
  return response;
};
export const contractWBSController = async (data) => {
  const response = await axiosWrapper.post(
    `${getBackendUrl()}/contracts/contractWBSController`,
    data,
  );
  return response;
};
export const getItemBreakUpDetails = async (data) => {
  const response = await axiosWrapper.post(
    `${getBackendUrl()}/contracts/getItemBreakUpDetails`,
    data,
  );
  return response;
};
export const getFormWBSItemsAndAssociatedBoqItems = async (data) => {
  const response = await axiosWrapper.post(
    `${getBackendUrl()}/forms/getFormWBSItemsAndAssociatedBoqItems`,
    data,
  );
  return response;
};
export const manageContractTemplate = async (data) => {
  const response = await axiosWrapper.post(
    `${getBackendUrl()}/contracts/manageContractTemplate`,
    data,
  );
  return response;
};
export const getFormsDataForChart = async (data) => {
  const response = await axiosWrapper.post(
    `${getBackendUrl()}/forms/getFormsDataForChart`,
    data,
  );
  return response;
};
export const getRelatedContractsDataForChart = async (data) => {
  const response = await axiosWrapper.post(
    `${getBackendUrl()}/contracts/getRelatedContractsDataForChart`,
    data,
  );
  return response;
};
export const getFormTasksAndAssociatedBoqItems = async (data) => {
  const response = await axiosWrapper.post(
    `${getBackendUrl()}/forms/getFormTasks`,
    data,
  );
  return response;
};
export const getFormBoqItems = async (data) => {
  const response = await axiosWrapper.post(
    `${getBackendUrl()}/forms/getFormBoqItems`,
    data,
  );
  return response;
};
export const syncContractController = async (data) => {
  const response = await axiosWrapper.post(
    `${getBackendUrl()}/contracts/syncContractController`,
    data,
  );
  return response;
};
export const getContractVersionDetails = async (data) => {
  const response = await axiosWrapper.post(
    `${getBackendUrl()}/contracts/getContractVersionDetails`,
    data,
  );
  return response;
};
export const createMirrorOfLatestContractVersion = async (data) => {
  const response = await axiosWrapper.post(
    `${getBackendUrl()}/contracts/createMirrorOfLatestContractVersion`,
    data,
  );
  return response;
};
export const revertContractDraftMirror = async (data) => {
  const response = await axiosWrapper.post(
    `${getBackendUrl()}/contracts/revertContractDraftMirror`,
    data,
  );
  return response;
};
export const validateAndApplyDraftChangesIntoNewVersion = async (data) => {
  const response = await axiosWrapper.post(
    `${getBackendUrl()}/contracts/validateAndApplyDraftChangesIntoNewVersion`,
    data,
  );
  return response;
};

export const getContractTaskHierarchy = async (data) => {
  const response = await axiosWrapper.post(
    `${getBackendUrl()}/forms/getContractTaskHierarchy`,
    data,
  );
  return response;
};
export const fetchSummaryOfChanges = async (data) => {
  const response = await axiosWrapper.post(
    `${getBackendUrl()}/contracts/fetchSummaryOfChanges`,
    data,
  );
  return response;
};
export const getContractLinkedBoqItems = async (data) => {
  const response = await axiosWrapper.post(
    `${getBackendUrl()}/contracts/getContractLinkedBoqItems`,
    data,
  );
  return response;
};
export const updateContractTasks = async (data) => {
  const response = await axiosWrapper.post(
    `${getBackendUrl()}/contracts/updateContractTasks`,
    data,
  );
  return response;
};
export const getContractAllVersions = async (data) => {
  const response = await axiosWrapper.post(
    `${getBackendUrl()}/contracts/getContractAllVersions`,
    data,
  );
  return response;
};
