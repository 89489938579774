import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { forwardRef, useRef } from "react";

const CheckBoxComponent = forwardRef((props: any, ref: any) => {

    const btnRef = ref || useRef();

    return (
        <Checkbox
            {...props}
            ref={btnRef}
        />
    )
})
export default CheckBoxComponent