import { useEffect } from "react";

const useValidationWrapper = (props) => {
  const { value, name, validator, dependencies = [] } = props;
  useEffect(() => {
    if (validator && name && validator.validate) {
      validator.validate({ value, name });
    }
  }, [value && value.toString(), ...dependencies]);
  return {};
};

export default useValidationWrapper;
