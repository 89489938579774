import useValidationWrapper from "./useValidationWrapper";
import _ from "lodash";

function ValidationWrapperWeb(props) {
  useValidationWrapper(props);
  return (
    <div>
      <div>{props.children}</div>
      {props.name && (
        <div className="text-negative_communication mt-2 text-xs">
          {_.get(props, `validator.error.${[props.name]}`)}
        </div>
      )}
    </div>
  );
}

export default ValidationWrapperWeb;
