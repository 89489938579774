import _ from "lodash";

const IGNORED_NETWORK_ERRORS = ["ERR_NETWORK", "ERR_CANCELED", "ECONNABORTED"];

export function sentryBeforeSend(event, hint) {
  const networkError = _.get(hint, "originalException.code");
  if (
    IGNORED_NETWORK_ERRORS.includes(networkError) ||
    _.get(hint, "originalException.status") == "401" ||
    (_.get(hint, "originalException.status") == "403" &&
      _.get(hint, "originalException.response.data.errorCode") === 1001)
  ) {
    return null;
  }
  return event;
}
