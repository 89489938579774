import _ from "lodash";
import { store } from "@Store";

export const getBackendUrl = () => {
  const state = store && store.getState();
  return _.get(state, "backendUrl") || process.env.REACT_APP_CUBE_BACKEND;
};

export const getImageUrl = () => {
  const state = store && store.getState();
  return _.get(state, "imageUrl") || process.env.REACT_APP_IMAGES_PUBLIC_URL;
};
