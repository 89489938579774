import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';

const DateTimeComponent = React.forwardRef((props, ref) => {

    const dateRef = ref || React.useRef()
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker {...props} maxDate={props.maxDate? dayjs(props.maxDate):null} maxDateTime={props.maxDateTime? dayjs(props.maxDate):null} minDate={props.minDate? dayjs(props.minDate):null} minDateTime={props.minDateTime? dayjs(props.minDate):null} value={props.value ? dayjs(props.value) : null} ref={dateRef} />
        </LocalizationProvider>
    );
})
export default DateTimeComponent